import React, { Component } from 'react';


class Header extends Component {
    render() {
        return (
            <div className='topHeader'>
                <a href='/'>
                    <h1>Lindsay Piethe</h1>
                    <h5 >FRONT END WEB DEVELOPER</h5>
                   
                </a>
            </div>


        );
    }
}

export default Header;